import React, { Component } from 'react';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import AppointmentSlotViewModel from '../../helpers/AppointmentSlotViewModel'
import { readAppointmentSlot, readLocation } from "../../helpers/Database"

import { withTrans } from "../../i18n/withTrans"

class AppointmentReview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slot: props.slot,
      slotOption: new AppointmentSlotViewModel(null, props.slot),
      location: null,
      contact: null
    };
  }

  componentDidMount() {
    if (this.state.slot.location) {
      readLocation(this.state.slot.location.id, this.updateLocation)
    }
  }

  updateLocation = (location) => {
    console.log("updateLocation", location)
    this.setState({
      location: location
    })
  }

  showLocation = (location) => {

  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" gutterBottom>
              {t('appointment.request.message')}
            </Typography>
          </Grid>
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h2" gutterBottom className="">
              {t('appointment.request.appointment')}
            </Typography>
            {this.state.slot && (
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>{this.state.slotOption.appointmentDay}</Typography>
                    <Typography variant="body2" gutterBottom>{this.state.slotOption.appointmentTime}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">{this.state.slotOption.locationName}</Typography>
                    <Typography variant="body2">{this.state.slotOption.locationStreet}</Typography>
                    <Typography variant="body2">{this.state.slotOption.locationArea}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <Link href={this.state.slotOption.locationMapURL} target="_blank">
                        {t('appointment.request.link')}
                      </Link>
                    </Typography>
                  </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </React.Fragment >
    )
  }
}

export default withTrans(AppointmentReview);
