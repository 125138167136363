import React, { Component } from 'react'

import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import AppointmentContactForm from './AppointmentContactForm';
import AppointmentDatePicker from './AppointmentDatePicker';
import AppointmentReview from './AppointmentReview';

import { createAppointmentRequest } from '../../helpers/Database';

import { withTrans } from "../../i18n/withTrans"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

class AppointmentFlow extends Component {

    constructor() {
        super();

        this.state = {
            activeStep: 0,
            appointmentRequestId: null,
            appointmentRequest: null,
            appointmentSlot: null,
            contact: {}
        };
    }

    updateAppointmentRequest = (appointmentRequestDoc) => {
        this.setState({
            appointmentRequestId: appointmentRequestDoc.id,
            activeStep: 2
        })
    }

    steps = ['appointment.slot.title', 'appointment.contact.title', 'appointment.request.title'];

    handleSelectAppointmentSlot = (appointmentSlotId, appointmentSlot) => {

        if (appointmentSlotId && appointmentSlot) {
            this.setState({
                appointmentSlotId: appointmentSlotId,
                appointmentSlot: appointmentSlot,
                activeStep: 1
            })
        }
    }

    handleChangeAppointmentSlot = event => {

        // Go back to step 0 to change slot
        this.setState({
            activeStep: 0
        })
    }

    handleFillContact = contact => {

        this.setState({
            contact: contact
        })

        if (this.state.appointmentSlot && contact) {
            createAppointmentRequest(this.state.appointmentSlotId, this.state.appointmentSlot, contact, this.updateAppointmentRequest)
        }
    }

    getStepContent() {
        switch (this.state.activeStep) {
            case 0:
                return <AppointmentDatePicker slot={this.state.appointmentSlot} onSelectedSlot={this.handleSelectAppointmentSlot} />;
            case 1:
                return <AppointmentContactForm contact={this.state.contact} slot={this.state.appointmentSlot} onFilledContact={this.handleFillContact} onChangeAppointmentSlot={this.handleChangeAppointmentSlot} />;
            case 2:
                return <AppointmentReview contact={this.state.contact} slot={this.state.appointmentSlot} />;
            default:
                throw new Error('Unknown step');
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                <Stepper activeStep={this.state.activeStep}>
                    {this.steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{t(label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {this.getStepContent()}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default withTrans(AppointmentFlow);